import styled, { css } from 'styled-components';
import { breakpointUp, color, spacer, variable } from 'theme';
import { BREAKPOINTS } from '../../../constants/breakpoints';
import Box from '../../Box/Box';
import Flex from '../../Flex/Flex';
import ShopLogo from '../../Layout/ShopLogo/ShopLogo';

export const ModalHeader = styled(Box)`
  position: relative;
  background-color: ${color('white')};
  ${variable('border-bottom')};
  padding: ${spacer(50)};
`;

export const StyledShopLogo = styled(ShopLogo)`
  position: relative;
  height: 50px;
  max-width: 150px;
  margin-left: ${spacer(100)};
`;

export const ModalItem = styled(Flex)`
  padding: ${spacer(100)} 0px;

  ${breakpointUp(
    BREAKPOINTS.MD,
    css`
      padding: 0px ${spacer(100)};
    `
  )};

  &:first-child {
    border-bottom: 1px solid ${color('primary-100')};

    ${breakpointUp(
      BREAKPOINTS.MD,
      css`
        border-right: 1px solid ${color('primary-100')};
        border-bottom: none;
      `
    )};
  }
`;
