import { Modal } from 'components/Layout';
import useRouter from 'hooks/useRouter';
import { useEffect, useState } from 'react';
import { useCookies } from 'react-cookie';
import { useIntl } from 'react-intl';
import config from '../../../config';
import { DEFAULT_LOCALE } from '../../../constants/i18n';
import useLocalizedUrls from '../../../hooks/useLocalizedUrls';
import Box from '../../Box/Box';
import Button from '../../Button/Button';
import Typography from '../../Typography/Typography';
import { ModalHeader, ModalItem, StyledShopLogo } from './LanguageModal.styled';

const { i18n } = config;

const LanguageModal = () => {
  const { getLocalizedRouteByLocale } = useLocalizedUrls();
  const router = useRouter();
  const { locale: routerLocale, locales } = router;
  const { formatMessage } = useIntl();
  const [cookies, setCookie] = useCookies();
  const [show, setShow] = useState(false);

  const changeLocale = async (newLocale: string) => {
    i18n?.key &&
      setCookie(i18n.key, newLocale, {
        maxAge: i18n.maxAge,
        path: '/',
      });
    setShow(false);

    if (newLocale === routerLocale) {
      return;
    }

    const newRoute = getLocalizedRouteByLocale(newLocale);

    router.push({
      pathname: newRoute.pathname,
      query: newRoute.query,
    });
  };

  useEffect(() => {
    const localeCookie = config?.i18n?.key ? cookies?.[config.i18n.key] : null;
    if (!localeCookie) {
      setShow(true);
    }
  }, [routerLocale]);

  if (!show) return null;

  return (
    <Modal flyOverOnMobile={false} show>
      <ModalHeader className="language-modal-header">
        <StyledShopLogo />
      </ModalHeader>
      <Box padding={75}>
        <Box paddingBottom={200} paddingTop={200}>
          <div className="grid gap-6 md:grid-cols-2">
            {locales
              .filter((filterLocale) => filterLocale !== DEFAULT_LOCALE)
              .map((locale) => (
                <ModalItem key={locale} alignItems="center" flexDirection="column" justifyContent="center">
                  <Typography
                    html={formatMessage({
                      id: `language_popup_${locale}_text`,
                    })}
                    textAlign="center"
                    type="h3"
                  />
                  <Button color="black" onClick={() => changeLocale(locale)}>
                    {formatMessage({
                      id: `language_popup_${locale}_button`,
                    })}
                  </Button>
                </ModalItem>
              ))}
          </div>
        </Box>
      </Box>
    </Modal>
  );
};

export default LanguageModal;
